window.global = window;
window.process = {
	env: { DEBUG: undefined },
};

/**
 * @see https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
 */
if (!('toJSON' in Error.prototype))
	Object.defineProperty(Error.prototype, 'toJSON', {
		value: function () {
			return Object.getOwnPropertyNames(this).reduce((alt, key) => {
				alt[key] = this[key];
				return alt;
			}, {});
		},
		configurable: true,
		writable: true,
	});
